import React from "react";

import ErrorPage from "@/components/ErrorPage";
import Layout from "@/components/Layout";

const _error = () => {
  return (
    <Layout hideFooter={true}>
      <ErrorPage />
    </Layout>
  );
};

export default _error;

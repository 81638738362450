import Link from "next/link";
import React from "react";

import { PAGE_CATEGORY_ENUM } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer";
import { ACDL_GLOBAL_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/global-tracking";
import { IMAGE_ASSETS_CDN_PATH } from "@/constants";
import useAcdlGlobalPageTracking from "@/hooks/useAcdlGlobalTracking";

import styles from "./ErrorPage.module.scss";

const ErrorPage = () => {
  // ACDL Global Tracking Custom Hook
  useAcdlGlobalPageTracking(
    ACDL_GLOBAL_TRACKING.programmaticUrlRoute,
    "error",
    PAGE_CATEGORY_ENUM.ERROR
  );

  return (
    <section className={styles.errorPage}>
      <div className={styles.header}>
        <div className={styles.logoWrap}>
          <img
            alt="Equinox"
            className={styles.logo}
            src={`${IMAGE_ASSETS_CDN_PATH}/equinox-logo.svg`}
          />
        </div>
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>You&apos;ve left your comfort zone</h1>

        <p className={styles.description}>
          We couldn’t find the page you’re looking for. Please use the
          navigation above or return to the homepage.
        </p>

        <Link href="/" legacyBehavior>
          <a className={styles.returnHomeButton}>Return to Homepage</a>
        </Link>
      </div>
    </section>
  );
};

export default ErrorPage;
